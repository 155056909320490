@media (min-width: 600px) {
  .volago-dialog {
    .mat-mdc-dialog-container {
      min-width: 400px;
      max-width: 800px;
    }
  }
}

@media (max-width: 600px) {
  .volago-dialog {
    min-width: 100%;
    height: 100%;
    position: relative;
  }
}